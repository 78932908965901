.modal {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.66);
  transition: opacity 500ms;
  opacity: 1;
  z-index: 999;

  display: flex;
  width: 100vw;
  height: 100vh;

  justify-content: center;
  align-items: center;

  overflow-x: hidden;
  overflow-y: hidden;
}

.modal:target {
  visibility: visible;
  opacity: 1;
}

@media (min-width: 720px) {
  .popup {
    width: 50vw;
    max-width: 50vw;
  }
}

@media (min-width: 1024px) {
  .popup {
    width: 33vw;
    max-width: 33vw;
  }
}

.popup {
  margin: auto;
  background-color: #066fb5;
  border-radius: 8px;
  transition: all 5s ease-in-out;
  /*max-width: 33vw;*/
  width: 100%;
}

h2 {
  padding: 1rem;
  margin: 0;
}

.popup .close {
  transition: all 200ms;
  font-size: 30px;
  font-weight: bold;
  text-decoration: none;
  border-radius: 8px;
  box-shadow: none;
  border: none;
  color: #066fb5;
  margin-right: 10px;
}

.popup .close:hover {
  color: black;
}

.Header {
  flex-direction: row;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid white;
}
