.logo-container {
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: 1fr 60px;
  background-color: white;
  height: 60px;
}

.logo-img {
  height: 60px;
  justify-self: center;
}

.user {
  background: url("../../../assets/images/user.png") no-repeat center center;
  background-size: contain;
  height: 40px;
  margin-top: 10px;
  background-color: white;
}
