.button {
  background-color: #04395c;
  padding: 1rem 1.5rem;
  border: none;
  font-size: 2vh;
  font-weight: bold;
  box-shadow: 0 4px 8px 0 rgb(0 0 0 / 15%);
  border-radius: 8px;
  color: white;
}

.button:disabled {
  opacity: 0.5;
}

.white-btn {
  background-color: white;
  color: #424242;
}