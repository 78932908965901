@media (min-width: 720px) {
  .summary-form-container {
    width: 500px;
    max-width: 500px;
  }
}

.summary-form-container {
  min-height: 100%;
  width: 80vw;
  display: flex;
  margin: 0 auto;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 30px;
  padding-bottom: 30px;
}

.summary-form-container > * {
  width: 100%;
  text-align: center;
}

.summary-form-container > .ticket-details-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  max-width: 360px;
}

.summary-form-container > .ticket-details-container div {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.summary-form-container > .total-price {
  font-size: 3vh;
  font-weight: bolder;
}

.summary-form-container > .actions {
  display: grid;
  grid-auto-flow: column;
  grid-gap: 30px;
  grid-template-columns: 1fr 1fr;
}

.summary-form-container > .input-wrapper {
  height: 50px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.summary-form-container > .input-wrapper > .react-datepicker-wrapper {
  height: 100%;
  width: 100%;
}

.summary-form-container > .input-wrapper > .react-datepicker-wrapper input {
  padding: 0.75rem;
  /*height: calc(48px - 1.5rem);*/
  /*width: calc(100% - 3rem);*/
  height: 100%;
  width: calc(100% - 30px);
  text-align: center;
  line-height: 48px;
}

.summary-form-container > .input-wrapper > .react-datepicker-wrapper > div {
  height: 100%;
  width: 100%;
}

.summary-form-container > .input-wrapper > button {
  background: url("../../assets/images/calendar.svg") no-repeat center center;
  box-shadow: none;
  width: 50px;
  height: 50px;
}

.summary-form-container .react-datepicker-wrapper input {
  appearance: none;
  background-clip: padding-box;
  background-color: rgba(255, 255, 255);
  border: 1px solid #066fb5;
  border-radius: 8px;
  box-shadow: inset 0 1px 2px rgb(0 0 0 / 8%);
  color: #474747;
  display: block;
  font-size: 2vh;
  font-weight: 400;
  padding: 0.75rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  width: 100%;
  text-align: center;
}