@media (min-width: 720px) {
  .user-container {
    width: 500px;
    max-width: 500px;
  }
}

.user-container {
  display: flex;
  flex-direction: column;
  min-height: 100%;
  gap: 30px;
  width: 80vw;
  align-items: center;
  margin: 0 auto;
  justify-content: center;
  padding-bottom: 30px;
}

.user-container > * {
  width: 100%;
  text-align: center;
}