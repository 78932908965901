footer {
  display: grid;
  grid-auto-flow: column;
  grid-gap: 10px;
  grid-template-columns: 1fr 1fr 1fr;
  justify-items: center;
  width: 100vw;
  margin-bottom: 10px;
}

.footer a {
  color: white;
  font-size: 2vh;
  text-decoration: underline;
}
