.Input {
  appearance: none;
  background-clip: padding-box;
  background-color: rgba(255, 255, 255);
  border: 1px solid #066fb5;
  border-radius: 8px;
  box-shadow: inset 0 1px 2px rgb(0 0 0 / 8%);
  color: #474747;
  display: block;
  font-size: 2vh;
  font-weight: 400;
  padding: 0.75rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.Input-Error {
  border: 0.2vh solid red;
}

Input:focus {
  outline: none;
}

input[type="text"][disabled] {
  color: white;
  font-weight: bold;
}
