@media (min-width: 720px) {
  .home-container {
    width: 500px;
    max-width: 500px;
  }
}

.home-container {
  min-height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 30px;
  margin: 0 auto;
  width: 80vw;
  text-align: center;
  padding-bottom: 30px;
}

.home-container > * {
  width: 100%;
}

.home-container > .input-wrapper {
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: 1fr auto;
  grid-gap: 30px;
}

.home-container > .input-wrapper > button {
  border: none;
  border-radius: 0;
  background-size: cover;
  background: url('../../assets/images/camera.svg') no-repeat center center;
  box-shadow: none;
  margin: 0;
}