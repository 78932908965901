@media (min-width: 720px) {
  .payment-summary {
    width: 500px;
    max-width: 500px;
  }
}

.payment-summary {
  display: flex;
  flex-direction: column;
  width: 80vw;
  gap: 30px;
  justify-content: center;
  margin: 0 auto;
  min-height: 100%;
  text-align: center;
  padding-bottom: 30px;
}