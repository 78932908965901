body {
  margin: 0;
  font-family: "Open Sans SemiBold", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  touch-action: none;
  color: white;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
  monospace;
}

@font-face {
  font-family: "Open Sans";
  src: local("Open Sans"),
  url(./assets/fonts/OpenSans-Regular.ttf) format("truetype");
}

@font-face {
  font-family: "Open Sans Bold";
  src: local("Open Sans"),
  url(./assets/fonts/OpenSans-Bold.ttf) format("truetype");
}

@font-face {
  font-family: "Open Sans BoldItalic";
  src: local("Open Sans"),
  url(./assets/fonts/OpenSans-BoldItalic.ttf) format("truetype");
}

@font-face {
  font-family: "Open Sans ExtraBold";
  src: local("Open Sans"),
  url(./assets/fonts/OpenSans-ExtraBold.ttf) format("truetype");
}

@font-face {
  font-family: "Open Sans ExtraBoldItalic";
  src: local("Open Sans"),
  url(./assets/fonts/OpenSans-ExtraBoldItalic.ttf) format("truetype");
}

@font-face {
  font-family: "Open Sans SemiBold";
  src: local("Open Sans"),
  url(./assets/fonts/OpenSans-SemiBold.ttf) format("truetype");
}

@font-face {
  font-family: "Open Sans Light";
  src: local("Open Sans"),
  url(./assets/fonts/OpenSans-Light.ttf) format("truetype");
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

html,
body {
  height: 100%;
  width: 100%;
}

html * {
  box-sizing: border-box;
}

#root {
  height: 100%;
  width: 100%;
}

h1 {
  font-family: 'Open Sans ExtraBold', sans-serif;
  font-size: 3vh;
  text-decoration: none solid rgb(35, 100, 170);
  margin: 0;
  padding: 0;
}

p {
  font-family: 'Open Sans SemiBold', sans-serif;
  margin: 0;
  padding: 0;
  font-size: 2vh;
}

.page-container {
  width: 100%;
  height: 100%;
  overflow-y: auto;
}

.error {
  color: #cc0000;
}