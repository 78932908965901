@media (max-width: 500px) {
    .react-datepicker {
        display: flex;
        flex-direction: column;
    }

    .react-datepicker__navigation--next {
        right: 0 !important;
    }

    .react-datepicker__time-box {
        width: 238px !important;
        height: 25px;
        margin: 0 !important;
    }

    .react-datepicker__time-container {
        display: flex;
        flex-direction: column;
        width: 100%;
    }

    .react-datepicker__time-list {
        display: flex;
        overflow-y: hidden !important;
        height: 25px !important;
    }
}