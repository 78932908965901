@media (min-width: 720px) {
  .checkout-form-container {
    width: 500px;
    max-width: 500px;
  }
}

.checkout-form-container {
  width: 80vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100%;
  margin: 0 auto;
  gap: 30px;
  text-align: center;
  padding-bottom: 30px;
}

.checkout-form-container > * {
  width: 100%;
}

.checkout-form-container > .ticket-details-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  max-width: 360px;
}

.checkout-form-container > .ticket-details-container div {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.checkout-form-container > .ticket-details-main {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.checkout-form-container > input {
  width: calc(100% - 1.5rem);
}

.checkout-form-container > .ticket-details-main > .total-price {
  font-size: 3vh;
  font-weight: bolder;
}

.checkout-form-container > .tnc-row {
  display: flex;
  flex-direction: row;
  gap: 15px;
  position: relative;
}

.checkout-form-container > .actions {
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: 1fr 1fr;
  grid-gap: 30px;
}

input[type="checkbox"].ios8-switch {
  /*position: absolute;*/
  /*margin: 8px 0 0 16px;*/
  display: none;
  visibility: hidden;
}

input[type="checkbox"].ios8-switch + label {
  position: relative;
  padding: 5px 0 0 50px;
}

input[type="checkbox"].ios8-switch + label:before {
  content: "";
  position: absolute;
  display: block;
  left: 0;
  top: calc(50% - 16px);
  width: 40px; /* x*5 */
  height: 24px; /* x*3 */
  border-radius: 16px; /* x*2 */
  background: #fff;
  border: 1px solid #d9d9d9;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

input[type="checkbox"].ios8-switch + label:after {
  content: "";
  position: absolute;
  display: block;
  left: 0;
  top: calc(50% - 16px);
  width: 24px; /* x*3 */
  height: 24px; /* x*3 */
  border-radius: 16px; /* x*2 */
  background: #fff;
  border: 1px solid #d9d9d9;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

input[type="checkbox"].ios8-switch + label:hover:after {
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
}

input[type="checkbox"].ios8-switch:checked + label:after {
  margin-left: 16px;
}

input[type="checkbox"].ios8-switch:checked + label:before {
  background: #55d069;
}

/* LARGE */
input[type="checkbox"].ios8-switch-lg {
  margin: 34px 0 0 20px;
}

input[type="checkbox"].ios8-switch-lg + label {
  position: relative;
  padding: 0 0 0 60px;
}

input[type="checkbox"].ios8-switch-lg + label:before {
  width: 50px; /* x*5 */
  height: 30px; /* x*3 */
  border-radius: 20px; /* x*2 */
}

input[type="checkbox"].ios8-switch-lg + label:after {
  width: 30px; /* x*3 */
  height: 30px; /* x*3 */
  border-radius: 20px; /* x*2 */
}

input[type="checkbox"].ios8-switch-lg + label:hover:after {
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.3);
}

input[type="checkbox"].ios8-switch-lg:checked + label:after {
  margin-left: 20px; /* x*2 */
}