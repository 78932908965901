.error {
    display: none;
    position: absolute;
    inset: 0;

    background-color: rgba(0, 0, 0, 0.7);
    z-index: 100;
}

.show {
    display: flex;

    justify-content: center;

    align-items: center;
}

.errorMessage {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    max-width: 90%;
    gap: 0.5em;
    background-color: #eeeeee;

    border-radius: 0.5em;

    padding: 1em;
}

.ok {
    color: white;
    background-color: #066fb5;
    border: none;
    padding: 1em;
    border-radius: 0.5em;
    cursor: pointer;
}
.ok:active {
    background-color: #258dd2;
}